const Axios = require('axios').default;

const APIBaseUrl = 'http://dandyacepatchnotes.us-east-2.elasticbeanstalk.com';

export default class API {

    static async get(endpoint, params){
        let url;
        if (!params) url = APIBaseUrl + endpoint;
        else url = APIBaseUrl + endpoint + '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
        const response = await Axios.get(url);
        return response.data;
    }

    static async getImage(imgName){
        const url = APIBaseUrl + `get/getImage?img=${imgName}`;
        const response = await Axios.get(url);
        return response.data;
    }

    static async post(endpoint, json){
        const response = await Axios.post(APIBaseUrl + endpoint, json);
        return response.data;
    }

    static async getExternal(endpoint){
        const response = await Axios.get(endpoint);
        return response.data;
    }

    static async postExternal(endpoint, json){
        const response = await Axios.post(endpoint, json);
        return response.data;
    }
}