import React, {Component} from 'react';
import API from './../API/API';

import './../styles/patchPage.css';

export default class Patch extends Component {
    constructor(props){
        super(props);
        this.state = {
            patchNumber: window.location.href.split('/')[4],
            patchInfo: {
                brief: "",
                patchId: "",
                patchNumber: "",
                title: "",
                uploadDate: "",
            },
            loading: true,
        };
    }

    componentDidMount() {
        this.updatePatchInfo();
    }

    render = () => (
        <div style={Styles.pageWrapper}>
            <iframe 
                id="PatchView"
                src={`http://dandyacepatchnotes.us-east-2.elasticbeanstalk.com/get/getPatch?patchNumber=${this.state.patchNumber}`}
                style={Styles.patch}
            />

        </div>
    )

    updatePatchInfo = async () => {
        this.setState({
            patchInfo: await this.getPatchInfo(),
            loading: false,
        });
    }

    getPatchInfo = async () => {
        const response = await API.get("/get/getPatch", {
            patchNumber: this.state.patchNumber,
        });
        console.log(response);
        return response;
    }
}

const Styles = {
    pageWrapper : {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        height: '90vh',
    },
    patch: {
        border: 'none',
        width: '100%',
        height: '100%'
    }
}