import React, { Component } from "react";


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./../styles/pressKit.css";

export default class PressKit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patchList: [],
            loading: true,
            error: false,
        };
    }

    render = () => (
        <>
            <div className="headerA">
                <h1 className="headerTitleA">Press Kit!</h1>
            </div>

            <div className="bodyA">
                
                <div className="textContainerA">
                    <span>Download our <span className="highlight">Press Kit</span> for the latest in assets</span>
                </div>

                <div className="linksContainer">
                    <a href="" className="downloadLink">Download PressKit!</a>
                </div>

            </div>
        </>
    );
}
