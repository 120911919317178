import React, { Component } from "react";
import './../styles/landingPage.css'

import firstImage from './../assets/DandyAceLogo.png';
import cardsRight from './../assets/card2.png';
import cardsLeft from './../assets/card1.png';
import Link from './../assets/PlayNow.png';

import BGVideo from './../assets/video/BGVideo.mp4';

const LandingPage = () => {
    return (
        <div>
            <div className="firstHalf">
                <div className="videoContainer">
                    <video className="backgroundVideo" src={BGVideo} autoPlay={true} loop={true} muted={true}/>
                </div>
            </div>

            <div className="middle">
                <div className="cardContainer">
                    <img src={cardsLeft} className="card"/>
                </div>
                <div className="textContainer">
                    <div className="dandyLogoContainer">
                        <img src={firstImage} className="dandyLogo"/>
                    </div>
                    <div className="dandyTextContainer">
                        <span>Play as Dandy Ace, the fabulous magician, and survive the challenges of the ever changing palace created by Lele, the Green Eyed Illusionist. Find all of the magical cards, get help from Ace’s assistants and fight bizarre creatures and bosses to defeat Lele and escape the cursed mirror.</span>
                    </div>
                </div>
                <div className="cardContainer">
                    <img src={cardsRight} className="card"/>
                </div>
            </div>

            <div className="secondHalf">
                <div className="linksContainer" onClick={() => window.location.href = "https://store.steampowered.com/app/1037130/Dandy_Ace/"}>
                    <img src={Link} className='buttonLink' />
                </div>
            </div>
        </div>
    );
};

export default LandingPage