import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { slide as Menu } from "react-burger-menu";

import LandingPage from "./../pages/landingPage";
import PatchNotes from "./../pages/patchNotes";
import Patch from './../pages/Patch';
import PressKit from './../pages/pressKit';


import Logo from './../assets/DandyAceLogo.png'

import "./router.css";

const App = () => {
    //routes

    useEffect(() => {
        if (window.location.href === 'http://' + window.location.host + '/') {
            window.location.href = 'http://' + window.location.host + '/./Home';
        }
    }, []);

    return (
        <Router>
            <div className="headerContainer">
                <Menu>
                    <a className="navBtn" style={{textDecoration: 'none'}} id="home" href={'http://' + window.location.host + '/Home'}>Home</a>
                    <a className="navBtn" style={{textDecoration: 'none'}} id="about" href={'http://' + window.location.host + '/PatchNotes'}>Game Updates</a>
                    <a className="navBtn" style={{textDecoration: 'none'}} id="about" href={'http://' + window.location.host + '/PressKit'}>Press Kit</a>

                </Menu>
                <div className="logoContainer">
                    <img src={Logo} className="logo" />
                </div>
            </div>
            <div className="contentContainer">
                <Route path="/Home">
                    <LandingPage />
                </Route>

                <Route path="/PatchNotes">
                    <PatchNotes />
                </Route>

                <Route path="/Patch">
                    <Patch />
                </Route>

                <Route path="/PressKit">
                    <PressKit />
                </Route>
            </div>
        </Router>
    );
};

export default App;
