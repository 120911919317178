import React from 'react';

import dandyAce from './../assets/dandyAce.png';
import LinesEllipsis from 'react-lines-ellipsis'

const Patch = props => {

    const navitateToPatch = patchId => {
        console.log(props.patch);
        window.location.href = './patch/' + patchId;
    }

    const imgUrl = imgName => `http://dandyacepatchnotes.us-east-2.elasticbeanstalk.com/get/getImage?img=${imgName}`


    return (
        <div style={Styles.patchContainer} onClick={() => navitateToPatch(props.patch.patchNumber)}>
            <div style={Styles.imageContainer}>
                <img src={props.patch.imgPreview ? imgUrl(props.patch.imgPreview) : dandyAce} style={Styles.patchImage}/>
            </div>
            <div style={Styles.textContainer}>
                <span>{new Date(props.patch.dateTime).toLocaleDateString()} - {props.patch.patchNumber}</span>
                <span style={Styles.title}>{props.patch.title}</span>
                <LinesEllipsis 
                    text={props.patch.brief} 
                    style={Styles.description}
                    maxLine="2" 
                />
            </div>
        </div>
    )
}

export default Patch;

const Styles = {
    patchContainer: {
        backgroundColor: '#F9FFE0',
        width: '350px',
        height: '420px',
        margin: '20px',
        marginLeft: `5%`,
        marginRight: `5%`,
        overflow: 'hidden',
        cursor: 'pointer',
    },
    imageContainer: {
        height: '70%',
        display: 'flex',
        justifyContent: 'center',
    },
    patchImage: {
        height: '100%',
        width: 'auto',
    },
    textContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        color: '#404',
        padding: '5px',
        paddingLeft: '10px',
        fontWeight: 'bold',
        clamp: 2,
        margin: 0,
    },
    title: {
        fontSize: '28px',
        fontWheight: 'bolder',
        margin: '0px',
        textDecoration: 'underline', 
    },
    description: {
        fontSize: '20px',
        fontWheight: 'bolder',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        wordWrap: 'break-word',
        maxLines: 1,
    }
}