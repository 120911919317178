import React, { Component } from "react";

import Patch from './../components/Patch';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


import './../styles/patchnotes.css'
import bunny from './../assets/Chair Bunny.png';
import gameUpdates from './../assets/GameUpdates.png';
import API from './../API/API';

export default class PatchNotes extends Component {
    constructor(props){
        super(props);
        this.state = {
            patchList: [],
            loading: true,
            error: false,
        }
    }

    componentDidMount(){
        this.updatePatchesList();
    }

    render = () => (<>
        <div className="headerPatchNotes">
            <img src={gameUpdates} className="gameUpdates" />
            <img src={bunny} className="bunny" />
        </div>

        <div className="body">
            <div className="patchesContainer">

                {this.state.loading ? 
                    <Loader type="Oval" color="#A2A" height={80} width={80} visible={true}/> :
                    this.state.patchList.map((patch, index) => <Patch index={index} patch={patch} key={patch.patchId}/>)
                }
                
                {this.state.error ? 
                    <div style={{color: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}> 
                        <h1>Sorry, we have encountered an error while fetching the newest game updates from our server</h1>
                        <h2>Please try again later</h2> 
                    </div> :
                    null
                }

            </div>
        </div>

        <div className="footer">

        </div>
    </>);

    updatePatchesList = async () => {
        try{
            const patchList = await API.get('/get/getPatches', {});
            this.setState({
                loading: false,
                patchList: patchList,
            });
        }catch (e) {
            console.log(e);
            this.setState({
                loading: false,
                error: true,
            });
        }
    }
};